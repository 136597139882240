
import { defineComponent, onMounted, reactive, ref } from "vue";
import { phoneRegExp, CONTRACT_CODE_REGEX } from "@/core/constants/const";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { HopDongService } from "@/core/services/HopDong.service";
import { useRouterCustom } from "@/core/hooks/use-router-custom";
import { useLoading } from "@/core/hooks/use-loading";
import { useCreatingForm } from "@/core/hooks/use-creating-form";
import { getErrorMsg, swalErrNotification, swalNotification } from "@/core/helpers/utils";
import { AccountantItemResponse, IBienBanResponseBase } from '@/core/interfaces/ApiResponses';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import ConfirmationDialog from "@/components/confirmation-dialog/ConfirmationDialog.vue";
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from "../../../../components/shared/buttons/ButtonCustom.vue";
import TaiKhoanSelection from '../../../../components/shared/tai-khoan-selection/TaiKhoanSelection.vue';
import DivCol from '@/layout/_shared/DivCol.vue';
import LabelCustom from '@/components/shared/label-custom/LabelCustom.vue';
import DivRow from '@/layout/_shared/DivRow.vue';

const contractNumberValidator = (rule, value, fn) => {setTimeout(() => {
    if (!value.trim()) {
      fn(new Error("Bắt buộc phải nhập số hợp đồng"));
    }
    if (value && !CONTRACT_CODE_REGEX.test(value)) {
      fn(new Error("Số hợp đồng chỉ được chứa số và chữ in hoa"));
    } else {
      fn();
    }
  }, 500);
};

const isPhoneNumber = (_rule, value, fn) => {
  if (value && value.search(phoneRegExp) === -1) {
    return fn(new Error("Bắt buộc phải nhập số điện thoại đúng định dạng"));
  } else {
    fn();
  }
};

export default defineComponent({
  name: "contract-form",

  components: {DivRow, LabelCustom, DivCol, TaiKhoanSelection, ConfirmationDialog, PageRowLayout, ButtonCustom },

  setup() {
    const { goBack, push, replace, id, } = useRouterCustom();
    const { startLoading, endLoading } = useLoading();
    const { isCreating } = useCreatingForm();
    const { ButtonTypeColors, ButtonsType, ButtonTypes } = useButtonCustom();

    // form elements
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);



    const contract = reactive({
      bien_ban_kiem_nghiem: [] as IBienBanResponseBase[],
      contractNumber: '',
      signDate: '',
      expiryDate: '',
      partnerName: '',
      phoneNumber: '',
      address: '',
      taxNumber: '',
      accountId: 0,
      maChiTiet: '',
      loai_hop_dong: 1,
      so_hoa_don: '',
    });

    const loaiHopDongOptions = [
      {
        label: 'Hợp đồng mua bán',
        value: 1,
      },
      {
        label: 'Hợp đồng điều chỉnh',
        value: 2,
      }
    ]

    const isConfirmationDialogOpen = ref(false);

    const expiredDateValidation = (_, value, callback) => {
      if (value && contract.signDate && moment(contract.signDate).isAfter(value)) {
        callback(new Error('Ngày hết hạn không được trước ngày ký'));
      }

      callback();
    }

    const signedDateValidation = (_, value, callback) => {
      if (value && contract.expiryDate && moment(value).isAfter(contract.expiryDate)) {
        callback(new Error('Ngày hết hạn không được trước ngày ký'));
      }

      callback();
    }

    const validationRules = ref({
        contractNumber: [
          {
            validator: contractNumberValidator,
            trigger: 'blur',
          },
        ],
        signDate: [
          {
            required: true,
            message: 'Bạn phải nhập ngày ký hợp đồng',
            trigger: 'change',
          },
          {
            validator: signedDateValidation,
            trigger: 'change',
          },
        ],
        expiryDate: [
          {
            required: true,
            message: 'Bạn phải nhập ngày ký hợp đồng',
            trigger: 'change',
          },
          {
            validator: expiredDateValidation,
            trigger: 'blur',
          }
        ],
        partnerName: [
          {
            required: true,
            message: 'Tên đối tác là bắt buộc',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            validator: isPhoneNumber,
            trigger: 'blur',
          },
        ],
        taxNumber: [
          {
            required: true,
            message: 'Mã số thuế là bắt buộc phải nhập',
            trigger: 'blur',
          },
        ],
      })

    onMounted(async () => {
      setCurrentPageTitle('Hợp đồng');

      if (!isCreating.value) {
        try {
          const {
            data: {
              data: {
                bien_ban_kiem_nghiem,
                editable,
                ngay_ky,
                ten_doi_tac,
                dia_chi,
                so_dien_thoai,
                ngay_het_han,
                so_hoa_don,
                so_hop_dong,
                ma_so_thue,
                ma_ct,
                ma_tk,
                loai_hop_dong
              },
            },
          } = await HopDongService.fetchContractDetail(id.value);

          if (!editable) {
            await swalErrNotification(null, 'Không thể chỉnh sửa hợp đồng này');

            await replace(`/quan-ly/hop-dong/chi-tiet/${id.value}`);
          }

          contract.contractNumber = so_hop_dong;

          contract.bien_ban_kiem_nghiem = bien_ban_kiem_nghiem;

          contract.signDate = new Date(ngay_ky).toISOString();

          if (ngay_het_han) {
            contract.expiryDate = new Date(ngay_het_han).toISOString();
          }
          contract.partnerName = ten_doi_tac;

          contract.address = dia_chi;

          contract.phoneNumber = so_dien_thoai;

          contract.accountId = +ma_tk;

          contract.taxNumber = ma_so_thue;

          contract.maChiTiet = ma_ct;

          contract.loai_hop_dong = loai_hop_dong;

          contract.so_hoa_don = so_hoa_don;
        } catch (e) {
          await swalNotification(
            getErrorMsg(e, 'Xin lỗi, có vẻ có lỗi xảy ra khi lấy thông tin hợp đồng để cập nhật'),
            'error',
          )
          await replace("/trang-chu");
        }
      }
    });

    const onOpenConfirmationDialog = () => {
      isConfirmationDialogOpen.value = true;
    };

    const onSubmit = () => {
      if (!contract.accountId || !contract.maChiTiet) {
        isConfirmationDialogOpen.value = false;

        swalNotification(
          'Bắt buộc phải nhập thông tin tài khoản',
          'error',
        );

        return;
      }

      isConfirmationDialogOpen.value = false;
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          contract.signDate = moment(contract.signDate).format("YYYY/MM/DD");
          contract.expiryDate = moment(contract.expiryDate).format(
            "YYYY/MM/DD"
          );
          let fetchedId = 0;
          try {
            if (isCreating.value) {
              const res = await HopDongService.addNewContract(
                contract.contractNumber,
                contract.signDate,
                contract.expiryDate,
                contract.partnerName,
                contract.phoneNumber,
                contract.address,
                contract.taxNumber,
                contract.accountId,
                contract.maChiTiet,
                contract.loai_hop_dong,
                contract.so_hoa_don,
              );

              fetchedId = res.data.data.id;
            } else {
              await HopDongService.updateContract(
                +id.value,
                contract.contractNumber,
                contract.signDate,
                contract.expiryDate,
                contract.partnerName,
                contract.phoneNumber,
                contract.address,
                contract.taxNumber,
                contract.accountId,
                contract.maChiTiet,
                contract.so_hoa_don,
              );

              fetchedId = +id.value;
            }
            await swalNotification(
              `Hợp đồng được ${
                isCreating.value ? "tạo mới" : "chỉnh sửa"
              } thành công`
            )
            await replace(`/quan-ly/hop-dong/chi-tiet/${fetchedId}`);
          } catch (error) {
            await swalNotification(
              getErrorMsg(error, `Xin lỗi, không thể ${
                isCreating.value ? "tạo mới" : "chỉnh sửa"
              } hợp đồng, mời bạn thực hiện lại`),
              'error',
            )
          }
        } else return false;
      });
    };

    const resetForm = () => {
      Swal.fire({
        text: "Bạn chắc chắn muốn xoá hết nội dung để nhập lại?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Xoá",
        cancelButtonText: "Huỷ",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      }).then(async (result) => {
        if (result.value) {
          if (!isCreating.value) {
            contract.contractNumber = "";
          }
          contract.signDate = "";
          contract.expiryDate = "";
          contract.partnerName = "";
          contract.address = "";
          contract.taxNumber = "";
          contract.accountId = 0;
          contract.phoneNumber = "";
        }
      });
    };

    const onSelectedAccountSuccessHandler = ({ taiKhoanItem }: {
      taiKhoanItem: AccountantItemResponse,
    }) => {
      contract.partnerName = taiKhoanItem.ten_tai_khoan;
      if (taiKhoanItem.dia_chi) {
        contract.address = taiKhoanItem.dia_chi;
      }
      if (taiKhoanItem.ma_chi_tiet) {
        contract.taxNumber = taiKhoanItem.ma_chi_tiet;
        contract.maChiTiet = taiKhoanItem.ma_chi_tiet;
      }
      contract.accountId = +taiKhoanItem.ma_tai_khoan;
    };

    return {
      contract,
      isCreating,
      loading,
      formRef,
      loaiHopDongOptions,
      validationRules,
      isConfirmationDialogOpen,
      onOpenConfirmationDialog,
      onSubmit,
      resetForm,
      onSelectedAccountSuccessHandler,
      goBack, push, replace, id,
      startLoading, endLoading,
      ButtonTypeColors, ButtonsType, ButtonTypes,
    };
  },

  methods: {
    onAddPhieuNhapButtonClickHandler() {
      this.push(`/quan-ly/hop-dong/${this.id}/tao-phieu-nhap-kho`);
    }
  }
});
